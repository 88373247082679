/**
 _____   ____  __  _____    ____  __________ 
/__  /  / __ \/ / / /   |  / __ \/ ____/ __ \
  / /  / / / / / / / /| | / /_/ / __/ / / / /
 / /__/ /_/ / /_/ / ___ |/ _, _/ /___/ /_/ / 
/____/\___\_\____/_/  |_/_/ |_/_____/_____/  
                                             
**/
/** Responsive Breakpoints */
@keyframes spinner { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

@keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }

@keyframes slideFadeIn { 0% { opacity: 0;
    transform: translateY(2rem); }
  100% { opacity: 1;
    transform: translateY(0); } }

.hide { display: none !important; }

/**
$font-fat: abril-fatface, serif;
$font-m: benton-sans, Verdana, Arial, sans-serif;

$c-orange: 		#EA623D;
$c-blue: 		#28aad1;

$c-blue-d: 		#0E1821;
$c-blue-m: 		#2D3843;
$c-blue-l: 		#676F77;

$c-dark--gray-0: #32343d;
$c-dark--gray-1: #393c45;
$c-dark--gray-2: #3f424c;
$c-dark--gray-3: #4a4d59;
$c-dark--gray-4: #555963;
$c-dark--gray-5: #a5a7aa;

$c-light--gray-0: #32343d;
$c-light--gray-1: #4a4d59;
$c-light--gray-2: #818181;
$c-light--gray-3: #737A80;
$c-light--gray-4: #e4e4e4;
$c-light--gray-5: #FBFBFB;

*/
.m-notices { display: block; width: 60vw; position: fixed; top: 120px; left: 20vw; z-index: 99999; pointer-events: none; }

.m-notices span { display: block; width: 100%; margin-bottom: 10px; padding: 10px 15px; color: #fff; font-size: 14px; border-radius: 3px; opacity: 0; pointer-events: none; transition: opacity 250ms ease-in-out; position: relative; opacity: 0; transform: translateY(-5px); }

.m-notices span.show { pointer-events: all; animation-name: slideFadeIn; animation-duration: 250ms; animation-timing-function: ease-in-out; animation-delay: 0ms; animation-direction: normal; animation-fill-mode: forwards; }

.m-notices span button { display: block; background-color: transparent; border: 0; padding: 0; cursor: pointer; position: absolute; top: 6px; right: 10px; font-size: 20px; color: #fff; }

.m-notices--success { background-color: #2dce2d; }

.m-notices--warning { background-color: #EA623D; }

.m-notices--error { background-color: #ef1f4f; }

.m-body--frontend .m-notices { width: 40vw; left: 5vw; }

.table tbody tr:hover { background-color: #ededed !important; }
