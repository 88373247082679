/**
$font-fat: abril-fatface, serif;
$font-m: benton-sans, Verdana, Arial, sans-serif;

$c-orange: 		#EA623D;
$c-blue: 		#28aad1;

$c-blue-d: 		#0E1821;
$c-blue-m: 		#2D3843;
$c-blue-l: 		#676F77;

$c-dark--gray-0: #32343d;
$c-dark--gray-1: #393c45;
$c-dark--gray-2: #3f424c;
$c-dark--gray-3: #4a4d59;
$c-dark--gray-4: #555963;
$c-dark--gray-5: #a5a7aa;

$c-light--gray-0: #32343d;
$c-light--gray-1: #4a4d59;
$c-light--gray-2: #818181;
$c-light--gray-3: #737A80;
$c-light--gray-4: #e4e4e4;
$c-light--gray-5: #FBFBFB;

*/
.m-notices {
	display: block;
    width: 60vw;
    position: fixed;
    top: 120px;
    left: 20vw;
    z-index: 99999;
    pointer-events: none;

	span {
		display: block;
		width: 100%;
		margin-bottom: 10px;
		padding: 10px 15px;
		color: #fff;
		font-size: 14px;
		border-radius: 3px;
		opacity: 0;
		pointer-events: none;
		transition: opacity 250ms ease-in-out;
		position: relative;

		opacity: 0;
		transform: translateY(-5px);

		&.show {
			pointer-events: all;
			animation-name: slideFadeIn;
			animation-duration: 250ms;   
			animation-timing-function: ease-in-out;        
			animation-delay: 0ms;
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		button {
			display: block;
			background-color: transparent;
			border: 0;
			padding: 0;
			cursor: pointer;
			position: absolute;
			top: 6px;
			right: 10px;
			font-size: 20px;
			color: #fff;
		}
	}
	&--success {
		background-color: $c-success;
	}

	&--warning {
		background-color: $c-warning;
	}

	&--error {
		background-color: $c-error;
	}
}
.m-body--frontend .m-notices {
	width: 40vw;
	left: 5vw;
}
.table tbody tr:hover {
	background-color: $c-light--gray-4 !important;
}