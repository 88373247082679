/**
 _____   ____  __  _____    ____  __________ 
/__  /  / __ \/ / / /   |  / __ \/ ____/ __ \
  / /  / / / / / / / /| | / /_/ / __/ / / / /
 / /__/ /_/ / /_/ / ___ |/ _, _/ /___/ /_/ / 
/____/\___\_\____/_/  |_/_/ |_/_____/_____/  
                                             
**/

@import '00-reset';

$font-fat: abril-fatface, serif;
$font-m: filson-pro, Verdana, Arial, sans-serif;

$c-orange: 		#EA623D;
$c-blue: 		#28aad1;
$c-violet: 		#b028dc;
$c-green:		#2dce2d;
$c-red:			#ef1f4f;

$c-blue-d: 		#0E1821;
$c-blue-m: 		#2D3843;
$c-blue-l: 		#676F77;

$c-dark--gray-0: #32343d;
$c-dark--gray-1: #393c45;
$c-dark--gray-2: #3f424c;
$c-dark--gray-3: #4a4d59;
$c-dark--gray-4: #555963;
$c-dark--gray-5: #a5a7aa;

$c-light--gray-0: #32343d;
$c-light--gray-1: #4a4d59;
$c-light--gray-2: #818181;
$c-light--gray-3: #aaaaaa;
$c-light--gray-4: #ededed;
$c-light--gray-5: #FBFBFB;

$c-success:		$c-green;
$c-warning:		$c-orange;
$c-error:		$c-red;


/** Responsive Breakpoints */
$brk-laptop: 	1440px;
$brk-menu: 		860px;
$brk-tab: 		768px;
$brk-ph-lrg: 	620px;
$brk-ph-md: 	520px;
$brk-ph-sm: 	375px;

@import '01-vendor';
@import '02-settings';
@import '03-layout';
@import '04-utilities';
@import '05-modules';