@mixin theme($style) {
	$root: &;
	@at-root {
		#{$style} {
			#{$root} {
				@content;
			}
		}
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}

}

@keyframes fadeIn {
	0% { 
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes slideFadeIn {
	0% { 
		opacity: 0;
		transform: translateY(2rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.hide {
	display: none !important;
}